<template>
  <gf-content v-loading="loading" overflow>
    <template #title>
      <div class="title">
        <span class="font-weight-normal mr-3">Stock Adjustment #{{ sa.sa_number }} </span>
        <gf-label v-if="sa.status === 1" inline pill type="primary">For Approval</gf-label>
        <gf-label v-if="sa.status === 2" inline light pill type="danger">Cancelled</gf-label>
        <gf-label v-if="sa.status === 3" inline pill type="danger">Rejected</gf-label>
        <gf-label v-if="sa.status === 4" inline pill type="info">Completed</gf-label>
        <gf-label v-if="sa.status === 5" inline pill type="warning">Void</gf-label>
      </div>
    </template>
    <template #toolbar>
      <gf-button v-if="isApprover && sa.status === 1" class="mr-1" size="sm" type="primary" @click="approveModalVisible = true">Approve</gf-button>
      <gf-button v-if="isApprover && sa.status === 1" class="mr-3" size="sm" type="warning" @click="rejectModalVisible = true">Reject</gf-button>
      <gf-button v-if="sa.status === 1" size="sm" type="danger" @click="cancelModalVisible = true">Cancel</gf-button>
      <gf-button v-if="sa.status === 4" size="sm" type="warning" @click="voidModalVisible = true">Void</gf-button>
    </template>
    <template #header>
      <div class="detail">
        <div>
          <span class="font-size-sm font-weight-normal mr-2">Reason:</span>
          <span class="font-size-sm">{{ sa.reason }}</span>
        </div>
        <div>
          <span class="font-size-sm font-weight-normal mr-2">Last Updated:</span>
          <span class="font-size-sm">{{ $DateFormat(sa.updated_at) }}</span>
        </div>
        <div>
          <span class="font-size-sm font-weight-normal mr-2">Total Units:</span>
          <span class="font-size-sm">{{ sa.quantity }}</span>
        </div>
      </div>
    </template>
    <el-dialog v-loading="approveModalLoading" :close-on-click-modal="false" :visible.sync="approveModalVisible" append-to-body title="Approve Stock Adjustment Request">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-primary svg-icon-xxl">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <rect height="24" width="24" x="0" y="0"/>
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10"/>
                  <rect fill="#000000" height="8" rx="1" width="2" x="11" y="7"/>
                  <rect fill="#000000" height="2" rx="1" width="2" x="11" y="16"/>
                </g>
              </svg>
            </span>
          </template>
          By approving this request, stocks count of the items will be updated
        </gf-alert>
        <template #footer>
          <gf-button class="mr-2" type="secondary" @click="approveModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <g fill="#000000" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                      <rect height="2" rx="1" width="16" x="0" y="7"/>
                      <rect height="2" opacity="0.3" rx="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " width="16" x="0" y="7"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="primary" @click="approve">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <rect height="24" width="24" x="0" y="0"/>
                    <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10"/>
                    <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </template>
            Confirm
          </gf-button>
        </template>
    </el-dialog>
    <el-dialog v-loading="rejectModalLoading" :close-on-click-modal="false" :visible.sync="rejectModalVisible" append-to-body title="Reject Stock Adjustment Request">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-warning svg-icon-xxl">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <rect height="24" width="24" x="0" y="0"/>
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10"/>
                  <rect fill="#000000" height="8" rx="1" width="2" x="11" y="7"/>
                  <rect fill="#000000" height="2" rx="1" width="2" x="11" y="16"/>
                </g>
              </svg>
            </span>
          </template>
          By rejecting this request, stocks count of the items will not be changed
        </gf-alert>
        <template #footer>
          <gf-button class="mr-2" type="secondary" @click="rejectModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <g fill="#000000" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                      <rect height="2" rx="1" width="16" x="0" y="7"/>
                      <rect height="2" opacity="0.3" rx="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " width="16" x="0" y="7"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="warning" @click="reject">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <rect height="24" width="24" x="0" y="0"/>
                    <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10"/>
                    <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </template>
            Confirm
          </gf-button>
        </template>
    </el-dialog>
    <el-dialog v-loading="cancelModalLoading" :close-on-click-modal="false" :visible.sync="cancelModalVisible" append-to-body title="Cancel Stock Adjustment Request">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-danger svg-icon-xxl">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <rect height="24" width="24" x="0" y="0"/>
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10"/>
                  <rect fill="#000000" height="8" rx="1" width="2" x="11" y="7"/>
                  <rect fill="#000000" height="2" rx="1" width="2" x="11" y="16"/>
                </g>
              </svg>
            </span>
          </template>
          By cancelling this request, stocks count of the items will not be changed
        </gf-alert>
        <template #footer>
          <gf-button class="mr-2" type="secondary" @click="cancelModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <g fill="#000000" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                      <rect height="2" rx="1" width="16" x="0" y="7"/>
                      <rect height="2" opacity="0.3" rx="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " width="16" x="0" y="7"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="danger" @click="cancel">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <rect height="24" width="24" x="0" y="0"/>
                    <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10"/>
                    <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </template>
            Confirm
          </gf-button>
        </template>
    </el-dialog>
    <el-dialog v-loading="voidModalLoading" :close-on-click-modal="false" :visible.sync="voidModalVisible" append-to-body title="Void Stock Adjustment Request">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-warning svg-icon-xxl">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <rect height="24" width="24" x="0" y="0"/>
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10"/>
                  <rect fill="#000000" height="8" rx="1" width="2" x="11" y="7"/>
                  <rect fill="#000000" height="2" rx="1" width="2" x="11" y="16"/>
                </g>
              </svg>
            </span>
          </template>
          Voiding this Stock Adjustment will revert all associated stock quantities to their previous levels.
        </gf-alert>
        <template #footer>
          <gf-button class="mr-2" type="secondary" @click="voidModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <g fill="#000000" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                      <rect height="2" rx="1" width="16" x="0" y="7"/>
                      <rect height="2" opacity="0.3" rx="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " width="16" x="0" y="7"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="warning" @click="voidSA">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <rect height="24" width="24" x="0" y="0"/>
                    <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10"/>
                    <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </template>
            Confirm
          </gf-button>
        </template>
    </el-dialog>
    <div>
      <gf-table :data="sa.items" :scrollable="false">
        <el-table-column label="Item Name" min-width="200">
          <template slot-scope="slot">
            <div class="gf-item">
              <div v-if="slot.row.variant.name === slot.row.product.name && slot.row.variant.is_pack === 0" class="gf-item__name">{{ slot.row.variant.name }}</div>
              <div v-else-if="slot.row.variant.is_pack === 0" class="gf-item__name">{{ slot.row.product.name }} - {{ slot.row.variant.name }}</div>
              <div v-else class="gf-item__name">
                <span v-if="slot.row.product.name === slot.row.variant.variant.name">{{ slot.row.variant.variant.name }} - {{ slot.row.variant.name }}</span>
                <span v-else>{{ slot.row.product.name }} - {{ slot.row.variant.variant.name }} - {{ slot.row.variant.name }}</span>
                <span>(Pack of {{ slot.row.variant.pack_size }})</span>
              </div>
              <div class="gf-item__sub">
                <span class="gf-item__sub__sku">SKU: {{ slot.row.variant.sku }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Qty. Change" min-width="120" prop="qty_change">
        </el-table-column>
        <el-table-column label="Cost per Item" min-width="120" prop="cost">
        </el-table-column>
        <el-table-column label="Available" min-width="120">
          <template slot-scope="slot">
            <span v-if="slot.row.variant.is_pack">{{ $NumberFormat(parseFloat(slot.row.stocks.find(sk => sk.warehouse_id === sa.warehouse_id).value || 0) / parseFloat(slot.row.variant.pack_size))}}</span>
            <span v-else>{{ $NumberFormat(parseFloat(slot.row.stocks[0].value || 0)) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="After" min-width="120">
          <template slot-scope="slot">
            <div v-if="sa.status === 1 || sa.status === 2 || sa.status === 3">
              <span v-if="slot.row.variant.is_pack">{{ $NumberFormat((parseFloat(slot.row.stocks.find(sk => sk.warehouse_id === sa.warehouse_id).value || 0) / parseFloat(slot.row.variant.pack_size)) + parseFloat(slot.row.qty_change)) }}</span>
              <span v-else>{{ $NumberFormat(parseFloat(slot.row.stocks[0].value || 0) + parseFloat(slot.row.qty_change)) }}</span>
            </div>
            <div v-else>
              <span v-if="slot.row.variant.is_pack">{{ $NumberFormat(parseFloat(slot.row.stocks.find(sk => sk.warehouse_id === sa.warehouse_id).value || 0) / parseFloat(slot.row.variant.pack_size)) }}</span>
              <span v-else>{{ $NumberFormat(parseFloat(slot.row.stocks[0].value || 0)) }}</span>
            </div>
          </template>
        </el-table-column>
      </gf-table>
    </div>
  </gf-content>
</template>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.br {
  border-right: 1px solid #EBEDF3;
}
</style>

<script>
// services
import StockAdjustmentService from '@/services/v1/StockAdjustment'

export default {
  data () {
    return {
      // ui
      loading: false,

      sa: {
        sa_number: null,
        status: null,
        reason: null,
        updated_at: null,
        items: []
      },

      // modal
      approveModalVisible: false,
      approveModalLoading: false,
      rejectModalVisible: false,
      rejectModalLoading: false,
      cancelModalVisible: false,
      cancelModalLoading: false,
      voidModalVisible: false,
      voidModalLoading: false,

      isApprover: false
    }
  },
  methods: {
    async approve () {
      try {
        this.approveModalLoading = true
        const saService = new StockAdjustmentService(this.$route.params.id)
        await saService.approve()

        this.$message.success(`${this.sa.sa_number} has been approved`)
        this.getStockAdjustment()
        this.approveModalVisible = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.approveModalLoading = false
      }
    },
    async reject () {
      try {
        this.rejectModalLoading = true
        const saService = new StockAdjustmentService(this.$route.params.id)
        await saService.reject()

        this.$message.success(`${this.sa.sa_number} has been rejected`)
        this.getStockAdjustment()
        this.rejectModalVisible = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.rejectModalLoading = false
      }
    },
    async cancel () {
      try {
        this.cancelModalLoading = true
        const saService = new StockAdjustmentService(this.$route.params.id)
        await saService.cancel()

        this.$message.success(`${this.sa.sa_number} has been cancelled`)
        this.getStockAdjustment()
        this.cancelModalVisible = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.cancelModalLoading = false
      }
    },
    async voidSA () {
      try {
        this.voidModalLoading = true
        const saService = new StockAdjustmentService(this.$route.params.id)
        await saService.void()

        this.$message.success(`${this.sa.sa_number} has been voided`)
        this.getStockAdjustment()
        this.voidModalVisible = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.voidModalLoading = false
      }
    },

    // services fetch
    async getStockAdjustment () {
      try {
        this.loading = true
        const saService = new StockAdjustmentService(this.$route.params.id)
        const result = await saService.get()
        this.sa = result.data
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.isApprover = this.$GetPermission('SA_APPROVAL')

    this.$emit('update:active', 1)
    await this.getStockAdjustment()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Stock control' },
      { title: 'Stock Adjustments' },
      { title: this.sa.sa_number },
      { title: 'Details' }
    ])
  }
}
</script>
